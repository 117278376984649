import React, { Component } from "react";
import {
  ParallaxProvider,
  ParallaxBanner,
} from "react-scroll-parallax";
import Slider from "react-slick";
import CountUp from "react-countup";
import Button from "../components/button";
import RealizationBox from "../components/realization_box";
import SVG from "react-inlinesvg";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Cookies from "universal-cookie";
import MarkdownRenderer from "react-markdown-renderer";
import ReactHtmlParser from "react-html-parser";
import Layout from "../components/layout.jsx";
import ReferenceBox from "../components/reference_box";
import Header from "../components/header";
import slugify from "slugify";

const cookies = new Cookies();

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counterFirst: 0,
      counterSecond: 0,
      formatStart: false,
      closeLoader: false,
      loader: true,
    };
  }

  componentDidMount() {
    const setImagePosition = () => {
      const [image] = document.querySelectorAll(
        "#imageLoader"
      );
      setTimeout(() => {
        const x = `${window.innerWidth / 2 -
            115 -
            image.offsetLeft}px`,
          y = `${window.innerHeight / 2 -
            25 -
            image.offsetTop}px`;
        image.style.transform = `translate(${x},${y})`;
        image.style.opacity = `1`;
        document.querySelector("body").style.overflowY =
          "hidden";
      }, 300);
    };
    if (cookies.get("homeLoader") !== "active") {
      this.setState({ loader: true });
      setImagePosition();
      setTimeout(() => {
        this.setState({ closeLoader: true });
        document.querySelectorAll(
          "#imageLoader"
        )[0].style.transform = `translate(${0},${0})`;
        document.querySelector("body").style.overflowY =
          "initial";
      }, 2000);
      setTimeout(() => {
        this.setState({ loader: false });

        cookies.set("homeLoader", "active", {
          path: "/",
          maxAge: 3600,
        });
      }, 3000);
    } else {
      this.setState({ loader: false });
    }
  }

  transformHtml = (data) => {
    const html = ReactHtmlParser(data);
    return (
      <article className="table-overflow">{html}</article>
    );
  };

  handleCounterStart = () => {
    setTimeout(() => {
      this.setState({
        counterFirst: 1_000,
        counterSecond: 13_000_000,
        formatStart: true,
      });
    }, 1000);
  };

  handleFormat = (value) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  render() {
    const realizationsSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      centerPadding: "40px 20px",
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const referencesSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      centerPadding: "50px",
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            centerPadding: "0",
          },
        },
      ],
    };
    const {
      counterFirst,
      counterSecond,
      loader,
      closeLoader,
    } = this.state;

    const {
      footerMenu,
      references,
      realizations,
      lang,
      menu,
      data: {
        seoTitle,
        h1_title,
        button_realizations,
        button_references,
        button_references_box,
        description,
        h2_realizations,
        h2_references,
      },
    } = this.props.pageContext;

    return (
      <Layout
        menu={menu}
        footerMenu={footerMenu}
        title={seoTitle}
      >
        <div
          className={`${loader ? "loader" : ""} ${
            closeLoader ? "close" : ""
          }`}
        >
          <div className="loader-overlay">
            <div className="bounce">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
              <div className="bounce4" />
              <div className="bounce5" />
            </div>
          </div>
          <Header lang={lang} />
          <div className="background-fill" />
          <div className="background back" />
          <ParallaxProvider>
            <ParallaxBanner
              className="parallax"
              layers={[
                {
                  image: require("../images/layers.svg"),
                  amount: 0,
                  slowerScrollRate: false,
                },
              ]}
              style={{
                height: "100vh",
                opacity: "0.6",
              }}
            />
          </ParallaxProvider>
          <div className="background front" />
          <section className="skew-content">
            <article className="skew-holder">
              <button
                className="scroll"
                onClick={() =>
                  window.scrollTo({
                    top: window.innerHeight - 100,
                    behavior: "smooth",
                  })
                }
              >
                <img
                  src={require("../images/scroll.svg")}
                  alt="Scroll mouse icon"
                />
              </button>
              <div className="main-wrapper-md ">
                <div className="exp-wrapper red-line">
                  <div className="text-holder">
                    <MarkdownRenderer markdown={h1_title} />
                    <p>{description}</p>
                  </div>
                  <Button
                    desktop
                    text={"Poznaj nas lepiej"}
                    href="/pl/o-firmie"
                  />
                </div>
              </div>
              <div className="machines-animation">
                <div className="animation-wrapper">
                  <SVG
                    src={require("../images/machine1.svg")}
                  />
                  <SVG
                    src={require("../images/machine2.svg")}
                  />
                  <SVG
                    src={require("../images/floor.svg")}
                  />
                </div>
              </div>
            </article>
          </section>
        </div>
        <div className="bg-gradient">
          <article className="counter-animation">
            <div className="main-wrapper-md">
              <Fade
                onReveal={() => this.handleCounterStart()}
                left
              >
                <div className="counter-wrapper">
                  <div className="counter-box">
                    <p>Zrealizowaliśmy ponad</p>
                    <CountUp
                      className="counter"
                      start={0}
                      end={counterFirst}
                      duration={6}
                      formattingFn={(value) =>
                        this.handleFormat(value)
                      }
                    />
                    <p>inwestycji</p>
                  </div>
                  <div className="counter-box">
                    <p>Wykonaliśmy ponad</p>
                    <CountUp
                      className="counter"
                      start={0}
                      end={counterSecond}
                      duration={6}
                      formattingFn={(value) =>
                        this.handleFormat(value)
                      }
                    />
                    <p>
                      m<sup>2</sup> posadzek
                    </p>
                  </div>
                </div>
                <Button
                  mobile
                  text={"Poznaj nas lepiej"}
                  href="/pl/o-firmie"
                />
              </Fade>
            </div>
          </article>
          <article className="last-realizations ">
            <div className="main-wrapper-md">
              <Flip top>
                <header className="section-header red-line">
                  <MarkdownRenderer
                    markdown={h2_realizations}
                  />
                  <Button
                    desktop
                    text="Zobacz wszystkie realizacje"
                    href="/pl/realizacje"
                  />
                </header>
                <div className="realizations-slider">
                  {realizations && realizations.length > 0 && (
                    <Slider {...realizationsSettings}>
                      {realizations
                        .filter(
                          ({ images, realization_year }) =>
                            !!images && !!realization_year
                        )
                        .sort(
                          (a, b) =>
                            b.realization_year -
                            a.realization_year
                        )
                        .map(
                          ({
                            id,
                            title,
                            place,
                            images,
                            link,
                          }) => (
                            <RealizationBox
                              key={id}
                              img={images}
                              title={title}
                              description={place}
                              href={link}
                            />
                          )
                        )}
                    </Slider>
                  )}
                </div>
                <Button
                  mobile
                  text={button_realizations}
                  href="/pl/realizacje"
                />
              </Flip>
            </div>
          </article>
          <article className="references">
            <Flip top>
              <div className="main-wrapper-md references-shadow">
                <header className="section-header red-line">
                  <MarkdownRenderer
                    markdown={h2_references}
                  />
                  <Button
                    desktop
                    text={button_references}
                    href="/pl/referencje"
                  />
                </header>
                <div className="references-slider">
                  <Slider {...referencesSettings}>
                    {references.length > 0 &&
                      references.map(
                        ({ id, name, logo, shortText }) => (
                          <ReferenceBox
                            key={id}
                            img={logo}
                            description={shortText}
                            href={`/${lang}/referencje/${slugify(
                              name,
                              {
                                replacement: "-",
                                remove: null,
                                lower: true,
                              }
                            )}`}
                            title={button_references_box}
                          />
                        )
                      )}
                  </Slider>
                </div>
                <Button
                  mobile
                  text={button_references_box}
                  href="/pl/referencje"
                />
              </div>
            </Flip>
          </article>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
