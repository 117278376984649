import React from "react";
import Button from "./button";
import MarkdownRenderer from "react-markdown-renderer";

const ReferenceBox = ({ img, description, href }) => (
  <article className="reference-box">
    {img && (
      <div
        className="image-holder"
        style={{
          backgroundImage: `url("${img
            .replace('["', "")
            .replace("]", "")}")`,
        }}
      />
    )}
    <div className="text-holder">
      <MarkdownRenderer markdown={description} />
    </div>
    <div className="btn-holder">
      <Button text="Pokaż więcej" href={href} />
    </div>
  </article>
);

export default ReferenceBox;
