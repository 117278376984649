import React from "react";
import slugify from "slugify";
import { Link } from "gatsby";

const RealizationBox = ({ img, title, place }) => {
  return (
    <Link
      to={`/pl/realizacje/${slugify(title, {
        replacement: "-",
        remove: null,
        lower: true
      })}`}
      className="realization-box"
    >
      <div
        className="box-image"
        style={{
          backgroundImage: `url("${
            img ? img : require(`../images/realizations/posadzka1.png`)
          }")`
        }}
      >
        <div className="overlay">
          <img src={require("../images/overlay-arrow.svg")} alt="White arrow" />
          <span>Więcej</span>
        </div>
      </div>
      <div className="box-content">
        <h5>{title}</h5>
        <p>{place}</p>
      </div>
    </Link>
  );
};

export default RealizationBox;
